import './header.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Storage, Translate } from 'react-jhipster';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { ABOUT, ARBEITGEBER, ARBEITNEHMER, BLOG, Brand, REGISTER } from './header-components';
import { AdminMenu, ArbeitgeberEntitiesMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { getLoginUrl } from 'app/shared/util/url-utils';
import { Link } from 'react-router-dom';
import { ArbeitnehmerEntitiesMenu } from 'app/entities/menu';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isInRegistrationFlow?: boolean;
  isArbeitgeber?: boolean;
  isAngestellter?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isOpenAPIEnabled?: boolean;
  currentLocale?: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();
  const togglerNode = useRef(null);
  const node = useRef<HTMLElement | null>(null);

  const handleClickOutside = e => {
    // Check if node.current or togglerNode.current contains the clicked target
    if ((node.current && node.current.contains(e.target)) || (togglerNode.current && togglerNode.current.contains(e.target))) {
      // Click within the menu or the NavbarToggler
      return;
    }
    // Click outside of the menu, close the menu
    setMenuOpen(false);
  };

  useEffect(() => {
    // Event Listener wenn der Komponent montiert ist
    document.addEventListener('mousedown', handleClickOutside);

    // Rückgabefunktion wenn der Komponenten de-mountiert ist
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = e => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header" className="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" light expand="md" fixed="top" className="navbar">
        {/* Bind the NavbarToggler to the togglerNode ref */}
        <NavbarToggler ref={togglerNode} aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            {/* not authenticated*/}
            {/*            {!props.isAuthenticated && <ARBEITNEHMER />}*/}
            {!props.isAuthenticated && <ARBEITGEBER />}
            {/*{!props.isAuthenticated && <ABOUT />}*/}
            {!props.isAuthenticated && <BLOG />}
            {!props.isAuthenticated && <REGISTER />}
            {!props.isAuthenticated && <NavItem className="flendly-nav-item" />}
            {!props.isAuthenticated && (
              <Link to={getLoginUrl()} className="headline-0 w-nav-link">
                LOGIN
              </Link>
            )}

            {/* authenticated */}
            {props.isAngestellter && <ArbeitnehmerEntitiesMenu />}
            {props.isArbeitgeber && <ArbeitgeberEntitiesMenu />}
            {props.isAuthenticated && (
              <NavItem className="flendly-nav-item">
                <img
                  src="../../../../content/images/logout2.png"
                  loading="lazy"
                  width="28"
                  sizes="(max-width: 767px) 100vw, 28px"
                  alt="Ein stilisiertes Such-Symbol, bestehend aus einer Lupe. Das Symbol repräsentiert die Suche nach Matches."
                  className="image-icon-navbar"
                />
                <NavLink href="/logout" className="headline-0 w-nav-link">
                  logout
                </NavLink>
              </NavItem>
            )}
            {/*<AdminMenu showOpenAPI={props.isOpenAPIEnabled} />*/}
            {/*<LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />*/}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
