import './home.scss';

import React, { useEffect } from 'react';

import { getRegisterUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import Testimonial from 'app/shared/slider/testimonial';

export const Arbeitgeberseite = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <div className="body-with-image">
      <div className="section-3">
        <div className="padding-section-30"></div>
        <div className="container-hero-std centered w-container">
          <div className="div-block-33-copy">
            <h1 className="heading-12">
              <strong className="bold-text-8">Keine Lust mehr, auf Bewerbungen zu warten, die nicht kommen?</strong>
            </h1>
            <div className="padding-15"></div>
            <p className="paragraph-15">
              Erstelle in 2 Min. Dein Unternehmensprofil und mach attraktiven Kandidaten aus unserer Datenbank direkt ein Angebot.
            </p>
            <div className="padding-15"></div>
            <div className="div-block-34">
              <a href={getRegisterUrl()} className="call-to-action-max w-button">
                JETZT Stellenangebote erhalten
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="section-ci-gradient">
        <div className="padding-20"></div>
        <div className="container-std">
          <h2 className="heading-1 white">
            <strong>Was kostet </strong>Flendly
          </h2>
          <h2 className="heading-4-copy white">
            Unser Motto - erst einstellen, dann bezahlen
            <br />
          </h2>
          <div className="div-block-36">
            <a className="button w-button">keinerlei Fixkosten</a>
            <a className="button w-button">499€ nur bei Einstellung </a>
            <a className="button w-button">Unlimitiert Angebote versenden</a>
          </div>
          <h2 className="heading-4-copy white">
            das gibt&#x27;s nur bei Flendly
            <br />
          </h2>
          <div className="padding-10"></div>
          <a href={getRegisterUrl()} className="call-to-action-max-copy w-button">
            jetzt unverbindlich &amp; kostenlos testen
          </a>
        </div>
        <div className="padding-20"></div>
      </section>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <h1 className="heading-1">
          <strong className="bold-text-10">Deine Vorteile bei Flendy</strong>
          <br />
        </h1>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <strong className="bold-text-11">Keine Stellenanzeigen, kein Warten</strong>
                </h1>
                <p className="margin-bottom-24px">Wir liefern Dir attraktive Kandidaten und Du machst mit einem Klick ein Angebot.</p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Hier KOSTENLOS LOSLEGEN
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Suche_Arbeitgeber.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-right">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <strong className="bold-text-11">Kandidatensuche nach Deinen Bedürfnissen</strong>
                </h1>
                <p className="margin-bottom-24px">
                  Sag uns was Dir wichtig ist und wir finden genau die passenden Kandidaten für Dein Unternehmen.
                </p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Wünsche angeben
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Auswahl_Arbeitnehmer_Eigenschaften.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <strong className="bold-text-11">Effizient und unschlagbar günstig </strong>
                </h1>
                <p className="margin-bottom-24px">
                  Swipe Dich durch unsere Datenbank und versende Angebote so viele Du willst. Kosten entstehen nur bei einer Einstellung.
                </p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Profil erstellen
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Angebot_AG_an_Arbeitnehmer.png.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-image6-green">
        <div className="padding-section-60"></div>
        <div className="container-std">
          <h2 className="heading-2 white">Was unsere Kunden über uns sagen</h2>
          <Testimonial />
        </div>
        <div className="padding-section-60"></div>
      </section>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">und so funktioniert&#x27;s</h1>
          <div className="w-layout-grid grid-std">
            <div id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01a6-95f59c7c" className="div-block-dark-grey">
              <h1 className="heading-1 white">1</h1>
              <h2 id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01a9-95f59c7c" className="heading-4 white">
                Unternehmen <br />
                Registrieren
              </h2>
              <p className="paragraph-std-white">Registriere Dich mit Deinem Unternehmen in wenigen Minuten auf unserer Plattform.</p>
            </div>
            <div id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01af-95f59c7c" className="div-block-dark-grey">
              <h1 className="heading-1 white">2</h1>
              <h2 id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01b2-95f59c7c" className="heading-4 white">
                Suchprofile
                <br />
                erstellen
              </h2>
              <p className="paragraph-std-white">
                Unser Algorithmus sucht auf Basis Deines definierten Suchprofils die zu Dir passenden Kandidaten.
              </p>
            </div>
            <div id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01b8-95f59c7c" className="div-block-dark-grey">
              <h1 className="heading-1 white">3</h1>
              <h2 id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01bb-95f59c7c" className="heading-4 white">
                Angebot <br />
                senden
              </h2>
              <p className="paragraph-std-white">
                Sende interessanten Kandidaten mit nur einem Klick ein individuelles und unverbindliches Angebot.
              </p>
            </div>
            <div id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01c1-95f59c7c" className="div-block-dark-grey">
              <h1 className="heading-1 white">4</h1>
              <h2 id="w-node-_6368f488-ae9e-b69c-9295-6ca90d0d01c4-95f59c7c" className="heading-4 white">
                Interview
                <br />
                führen
              </h2>
              <p className="paragraph-std-white">Nimmt der Kandidat Dein Angebot an, startest Du Deinen individuellen Bewerbungsprozess.</p>
            </div>
          </div>
          <div className="padding-20"></div>
          <a href={getRegisterUrl()} className="call-to-action-max w-button">
            Jetzt Profil anlegen
          </a>
        </div>
        <div className="padding-section-30"></div>
      </div>
    </div>
  );
};

export default Arbeitgeberseite;
