import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { joinGroup } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';

export const RoleMapperDialog = () => {
  useNavigate();
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);

  function handleRoleClicked(value: string) {
    console.log('handleRoleClicked: ' + value);

    dispatch(joinGroup(account.id, value));
  }

  return (
    <body className="body-light-grey">
      <div className="section-workflow-progress-bar">
        <div className="container-workflow w-container">
          <div className="padding-5"></div>
          <a href="../index.html" className="link-block w-inline-block">
            <img
              src="../../../content/images/Logo_plain_letters_cuttet.png"
              loading="lazy"
              width="262"
              sizes="(max-width: 479px) 100vw, 262px"
              alt="Flendly Logo, das als Home Button fungiert. Beim Anklicken des Logos wird man zur Startseite geleitet."
              srcSet="../../../content/images/Logo_plain_letters_cuttet-p-500.png 500w, ../../../content/images/Logo_plain_letters_cuttet-p-800.png 800w, ../../../content/images/Logo_plain_letters_cuttet-p-1080.png 1080w, ../../../content/images/Logo_plain_letters_cuttet-p-1600.png 1600w, ../../../content/images/Logo_plain_letters_cuttet.png 1639w"
              className="image"
            />
          </a>
        </div>
      </div>
      <div className="section-workflow-steps">
        <div className="container-std w-container">
          <div className="padding-40"></div>
          <h1 className="heading-2">
            Herzlich willkommen bei Flendly!
            <br />
          </h1>
          <div className="padding-10 padding-20"></div>
          <p className="paragraph-workflow">
            <strong>Sind Sie Arbeitnehmer oder Arbeitgeber?</strong>
          </p>
          <div className="padding-10"></div>
          <div id="2-single-select" className="w-layout-grid grid-17-single-select">
            <div
              id="w-node-_10c03d61-4cc1-68a1-a08c-72b87f05e775-1e4981b3"
              className="click-button-medium"
              onClick={() => handleRoleClicked('Angestellter')}
            >
              Ich bin Arbeitnehmer
            </div>
            <div
              id="w-node-_3c3a90d6-7e38-acb9-5aa0-4b360dd51c1f-1e4981b3"
              className="click-button-medium"
              onClick={() => handleRoleClicked('Arbeitgeber')}
            >
              Ich bin Arbeitgeber
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default RoleMapperDialog;
