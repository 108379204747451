import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { toast } from 'react-toastify';
import { getRecommendationsForArbeitgeber, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId as getSuchkriterienFor } from 'app/shared/reducers/suchkriterien.reducer';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { License } from 'app/shared/model/enumerations/license.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';

const SucheArbeitgeberDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const recommendationList: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitnehmerSuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const [recommendation, setRecommendation] = React.useState<IRecommendation>();

  useEffect(() => {
    if (recommendationList.length > 0) {
      const iRecommendation = recommendationList.find(r => r.id === id);

      if (iRecommendation) {
        setRecommendation(iRecommendation);

        dispatch(getArbeitnehmer(iRecommendation.arbeitnehmerId));
        dispatch(getSuchkriterienFor(iRecommendation.arbeitnehmerId));
      } else {
        toast.error('Error loading recommendation');
      }
    } else if (recommendationList.length === 0) {
      toast.error('Error loading recommendation list');
    }
  }, []);

  function handleNotInteresting() {
    const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };

    dispatch(updateRecommendation(updatedRecommendation))
      .then(() => {
        toast.info('Arbeitnehmer wurde abgelehnt');
      })
      .then(() => {
        dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: arbeitnehmerSuche.id, includeDeclined: false }));
      })
      .then(() => {
        navigate('/suche/arbeitgeber/' + arbeitnehmerSuche.id);
      })
      .catch(reason => toast.error('Error updating recommendation: \n' + reason));
  }

  function loaded() {
    return recommendation && arbeitnehmer && suchkriterien && arbeitgeber;
  }

  function getArbeitnehmerBeruf() {
    let iBeruf = suchkriterien.auswahlBeruf.find(beruf => beruf.auswahlBeruf == arbeitnehmerSuche.auswahlBeruf);
    return iBeruf ? iBeruf : suchkriterien.auswahlBeruf[0];
  }

  function isFlexibel() {
    return suchkriterien.arbeitsort['flexibel'] === 'flexibel';
  }

  return (
    <div>
      {loaded() && (
        <section>
          <div className="container-std w-container">
            <div className="padding-5"></div>
            <div className="wrapper-950px">
              <div className="card-details">
                <h1 className="card-heading">
                  <strong className="bold-text-4"></strong>
                </h1>
                <div className="card-text-blue">Ich suche</div>
                <div className="card-field-min-215px">{getArbeitnehmerBeruf().auswahlBeruf}</div>

                {/*Gehalt*/}
                <div className="card-text-blue">Gewünschtes mindestgehalt (netto)</div>
                <div className="card-field-min-215px">
                  {suchkriterien.auswahlGehalt}€ pro Monat
                  <br />
                </div>

                {/* Qualifikationen */}
                <div className="card-text-blue"> Meine Qualifikationen</div>
                <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea885-1e498191" className="div-block-19-copy">
                  <div>
                    <div className="div-block-18-copy">
                      <div className="w-layout-grid _1x1">
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea888-1e498191" className="text-grid-white-backr-accent">
                          Installateur
                        </div>
                      </div>
                      <div className="horizontal-padding-3"></div>
                      <div className="w-layout-grid grid-job-details-copy">
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea88c-1e498191" className="text-grid-white-backr">
                          {getArbeitnehmerBeruf()
                            .auswahlAusbildungsstand.map(value => Ausbildungsstand[value])
                            .join(', ')}
                        </div>
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea88e-1e498191" className="text-grid-white-backr">
                          {getArbeitnehmerBeruf()
                            .auswahlBerufserfahrung.map(value => Berufserfahrung[value])
                            .join(', ')}
                        </div>
                        <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea890-1e498191" className="text-grid-white-backr">
                          {getArbeitnehmerBeruf().zusatzqualifikationen}
                        </div>
                      </div>
                    </div>
                    <div className="padding-5-speziale"></div>
                  </div>
                </div>

                {/* Eigenschaften Arbeitnehmer*/}
                <div className="card-text-blue">Meine Eigenschaften</div>
                <div className="w-layout-grid grid-1x4-170px">
                  {arbeitgeber.auswahlArbeitgeberEigenschaften.map((eigenschaft, index) => (
                    <div key={index} id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8a8-1e498191" className="text-grid-white-backr">
                      {eigenschaft}
                    </div>
                  ))}
                </div>

                {/*Arbeitgebereigenschaften*/}
                <div className="card-text-blue">Das ist mir in einem Unternehmen wichtig</div>
                <div className="w-layout-grid grid-1x4-170px">
                  {suchkriterien.auswahlEigenschaften.map((eigenschaft, index) => (
                    <div key={index} id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8ad-1e498191" className="text-grid-white-backr">
                      {getEigenschaftByKey(eigenschaft)}
                    </div>
                  ))}
                </div>

                {/*License*/}
                <div className="card-text-blue">FührerscheinE</div>
                <div className="w-layout-grid grid-1x4-170px">
                  {suchkriterien.auswahlLicense.map((license, index) => (
                    <div key={index} id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8ad-1e498191" className="text-grid-white-backr">
                      {License[license]}
                    </div>
                  ))}
                </div>

                {/*Sprachen*/}
                <div className="card-text-blue">Sprachen</div>
                <div className="w-layout-grid">
                  {suchkriterien.auswahlSprache.map((sprache, index) => (
                    <div key={index} className={'flex-content-cust w-layout-grid grid-1x4-170px'}>
                      <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8d7-1e498191" className="text-block-left-copy">
                        {sprache.sprache}
                      </div>
                      <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8d9-1e498191" className="text-block-left-copy">
                        {sprache.niveau}
                      </div>
                    </div>
                  ))}
                </div>

                {/*Arbeitsverhältnis*/}
                <div className="card-text-blue">Gewünschtes Beschäftigungsverhältnis</div>
                <div className="w-layout-grid grid-1x4-170px">
                  {suchkriterien.auswahlArbeitsverhaeltnis.map((arbeitsverhaeltnis, index) => (
                    <div key={index} id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8e2-1e498191" className="text-grid-white-backr">
                      {Arbeitsverhaeltnis[arbeitsverhaeltnis]}
                    </div>
                  ))}
                </div>

                {/*Arbeitsort*/}
                <div className="card-text-blue">Wo ich arbeiten möchte</div>

                <div>
                  {isFlexibel() ? (
                    <div className="card-field-min-215px">
                      Ich bin komplett flexibel
                      <br />
                    </div>
                  ) : (
                    <div className="w-layout-grid grid-77">
                      <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f1-1e498191" className="text-block-left-copy">
                        {suchkriterien.arbeitsort['arbeitsort']}
                      </div>
                      <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f3-1e498191" className="text-block-5">
                        +{suchkriterien.arbeitsort['umkreis']}
                      </div>
                    </div>
                  )}
                </div>

                {/*Montage*/}
                <div className="card-text-blue">Bereitschaft für Montageeinsätze</div>
                <div className="card-field-min-215px">
                  {suchkriterien.auswahlMontage}
                  <br />
                </div>

                {/*Verfügbarkeit*/}
                <div className="card-text-blue">Verfügbarkeit</div>
                <div className="card-field-min-215px">{suchkriterien.auswahlZeitpunkt.zeitpunkt}</div>
                <div className="padding-10"></div>

                {/*Button bar*/}
                <div id="104-single-select45" className="w-layout-grid company-card-buttons">
                  <div
                    id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea904-1e498191"
                    className="call-to-action-red-max-100"
                    onClick={() => handleNotInteresting()}
                  >
                    Nicht interessant
                  </div>

                  <div
                    id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea906-1e498191"
                    className="call-to-action-max-100"
                    onClick={() => navigate('/suche/arbeitgeber/angebot/' + recommendation.id)}
                  >
                    Angebot senden
                  </div>
                </div>
                <div className="padding-5"></div>
              </div>
            </div>
            <div className="padding-20"></div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SucheArbeitgeberDetails;
