import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Arbeitgeberseite from 'app/modules/arbeitgeber/arbeitgeberseite';
import Arbeitnehmer from 'app/modules/arbeitnehmer/arbeitnehmer';
import About from 'app/modules/about/about';
import Faq from 'app/modules/about/faq';
import { Impressum } from 'app/modules/about/impressum';
import { Datenschutz } from 'app/modules/about/datenschutz';
import { Agb } from 'app/modules/about/agb';
import Contact from 'app/modules/about/contact';
import { EarlyBirdArbeitgeber } from 'app/modules/landing-pages/early-bird-arbeitgeber';
import { EarlyBirdArbeitnehmer } from 'app/modules/landing-pages/early-bird-arbeitnehmer';
import { WelcomeSite } from 'app/modules/login/welcome-site';
import { Blog } from 'app/modules/blog/blog';
import { BlogPostComponent } from 'app/modules/blog/blog-post-component';
import ArbeitnehmerUpdate from 'app/entities/arbeitnehmer/arbeitnehmer-update';
import MatchArbeitnehmer from 'app/entities/match/match-arbeitnehmer';
import MatchDetailArbeitnehmer from 'app/entities/match/match-detail-arbeitnehmer';
import SucheArbeitnehmerAuto from 'app/modules/suche/arbeitnehmer/suche-arbeitnehmer-auto';
import SucheArbeitnehmerDetails from 'app/modules/suche/arbeitnehmer/suche-arbeitnehmer-details';
import EinstellungenArbeitnehmer from 'app/modules/account/arbeitnehmer/einstellungen-arbeitnehmer';
import SicherheitArbeitnehmer from 'app/modules/account/arbeitnehmer/sicherheit-arbeitnehmer';
import MatchDetailArbeitgeber from 'app/entities/match/match-detail-arbeitgeber';
import { MatchArbeitgeber } from 'app/entities/match/match-arbeitgeber';
import SicherheitArbeitgeber from 'app/modules/account/arbeitgeber/sicherheit-arbeitgeber';
import EinstellungenArbeitgeber from 'app/modules/account/arbeitgeber/einstellungen-arbeitgeber';
import SucheArbeitgeber from 'app/modules/suche/arbeitgeber/suche-arbeitgeber';
import SucheArbeitgeberDetails from 'app/modules/suche/arbeitgeber/suche-arbeitgeber-details';
import SucheArbeitgeberProfileCreate from 'app/modules/suche/arbeitgeber/suche-arbeitgeber-profile-create';
import SucheArbeitgeberAngebot from 'app/modules/suche/arbeitgeber/suche-arbeitgeber-angebot';
import { ArbeitgeberUpdate } from 'app/entities/arbeitgeber/arbeitgeber-update';
import { SuchProfileArbeitgeber } from 'app/modules/suche/arbeitgeber/suche-arbeitgeber-profile';
import SucheArbeitgeberAnfrageReject from 'app/modules/suche/arbeitgeber/suche-arbeitgeber-anfrage-reject';
import SucheArbeitnehmerAngebotReject from 'app/modules/suche/arbeitnehmer/suche-arbeitnehmer-angebot-reject';
import WelcomeArbeitgeber from 'app/modules/register/WelcomeArbeitgeber';
import WelcomeArbeitnehmer from 'app/modules/register/WelcomeArbeitnehmer';
import { EarlyBird } from 'app/modules/landing-pages/early-bird';
import { IHeaderProps } from 'app/shared/layout/header/header';
import ErrorBoundary from 'app/shared/error/error-boundary';

const loading = <div>loading ...</div>;

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = (props: IHeaderProps) => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        {!props.isAuthenticated && <Route index element={<Arbeitnehmer />} />}
        {props.isAuthenticated && props.isAngestellter && (
          <Route
            index
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
                <SucheArbeitnehmerAuto />
              </PrivateRoute>
            }
          />
        )}

        {props.isAuthenticated && props.isArbeitgeber && (
          <Route
            index
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
                <SuchProfileArbeitgeber />
              </PrivateRoute>
            }
          />
        )}

        <Route path="blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPostComponent />} />

        <Route path="interview" element={<WelcomeSite />} />
        <Route path="arbeitnehmer" element={<Arbeitnehmer />} />
        <Route path="flendly" element={<About />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="faq" element={<Faq />} />
        <Route path="kontakt" element={<Contact />} />
        <Route path="datenschutz" element={<Datenschutz />} />
        <Route path="agb" element={<Agb />} />
        <Route path="early-bird" element={<EarlyBird />} />
        <Route path="early-bird-arbeitgeber" element={<EarlyBirdArbeitgeber />} />
        <Route path="early-bird-arbeitnehmer" element={<EarlyBirdArbeitnehmer />} />

        {/* Allgemein */}
        <Route path="arbeitgeber" element={<Arbeitgeberseite />} />

        {/* Profil */}
        <Route
          path="profil/arbeitnehmer"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <ArbeitnehmerUpdate />
            </PrivateRoute>
          }
        />

        <Route
          path="profil/arbeitgeber"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <ArbeitgeberUpdate />
            </PrivateRoute>
          }
        />

        <Route
          path="/register/arbeitnehmer/early-bird"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <WelcomeArbeitnehmer />
            </PrivateRoute>
          }
        />

        <Route
          path="/register/arbeitgeber/early-bird"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <WelcomeArbeitgeber />
            </PrivateRoute>
          }
        />

        {/*MATCHES*/}

        <Route
          path="matches/arbeitnehmer"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <MatchArbeitnehmer />
            </PrivateRoute>
          }
        />
        <Route
          path="matches/arbeitgeber"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <MatchArbeitgeber />
            </PrivateRoute>
          }
        />

        <Route
          path="matches/arbeitnehmer/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <MatchDetailArbeitnehmer />
            </PrivateRoute>
          }
        />

        <Route
          path="matches/arbeitgeber/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER, AUTHORITIES.ARBEITGEBER]}>
              <MatchDetailArbeitgeber />
            </PrivateRoute>
          }
        />

        {/* SUCHE */}

        <Route
          path="suche/arbeitnehmer"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <SucheArbeitnehmerAuto />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitnehmer/details/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <SucheArbeitnehmerDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitnehmer/anfrage/reject/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeberAnfrageReject />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitnehmer/angebot/reject/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER, AUTHORITIES.ANGESTELLTER]}>
              <SucheArbeitnehmerAngebotReject />
            </PrivateRoute>
          }
        />

        {/* --------------------------------------------------------------------------------*/}

        <Route
          path="suche/arbeitgeber/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeber />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitgeber/profiles"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SuchProfileArbeitgeber />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitgeber/profiles/update/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeberProfileCreate />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitgeber/profiles/update"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeberProfileCreate />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitgeber/details/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeberDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="suche/arbeitgeber/angebot/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SucheArbeitgeberAngebot />
            </PrivateRoute>
          }
        />

        {/*Einstellunge und Sicherheit*/}

        <Route
          path="arbeitnehmer/einstellungen"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <EinstellungenArbeitnehmer />
            </PrivateRoute>
          }
        />
        <Route
          path="suche/arbeitnehmer/angebot/reject/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <SucheArbeitgeberAnfrageReject />
            </PrivateRoute>
          }
        />

        <Route
          path="arbeitnehmer/sicherheit"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ANGESTELLTER]}>
              <SicherheitArbeitnehmer />
            </PrivateRoute>
          }
        />

        <Route
          path="arbeitgeber/einstellungen"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <EinstellungenArbeitgeber />
            </PrivateRoute>
          }
        />

        <Route
          path="arbeitgeber/sicherheit"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER]}>
              <SicherheitArbeitgeber />
            </PrivateRoute>
          }
        />

        <Route path="logout" element={<Logout />} />

        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ARBEITGEBER, AUTHORITIES.ANGESTELLTER]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />

        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
