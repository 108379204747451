import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getArbeitnehmerSuche, updateEntity as updateEntityLocal } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { toast } from 'react-toastify';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { defaultValue, IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { fetchBerufe } from 'app/shared/util/beruf.reducer';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { ZeitpunktAuswahl } from 'app/shared/components/zeitpunkt-auswahl';
import { SprachenAuswahl } from 'app/shared/components/language-auswahl';
import { FuehrerscheinAuswahl } from 'app/shared/components/license-auswahl';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';
import { MontageArbeitgeber } from 'app/shared/model/enumerations/montage.model';
import { License } from 'app/shared/model/enumerations/license.model';

export const SucheArbeitgeberProfileCreate = () => {
  const { id } = useParams<'id'>();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  /* Load model */
  const arbeitnehmersucheEntity: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  const [arbeitnehmersucheChange, setArbeitnehmersucheChange] = useState<IArbeitnehmersuche>(defaultValue);

  useEffect(() => {
    if (id) {
      dispatch(getArbeitnehmerSuche(id));
    }
  }, []);

  useEffect(() => {
    if (id && arbeitnehmersucheEntity.userID !== '') {
      setArbeitnehmersucheChange(arbeitnehmersucheEntity);
    }
  }, [arbeitnehmersucheEntity]);

  function getValidationErrors() {
    let validationErrors: string[] = [];

    if (arbeitnehmersucheChange.auswahlBerufserfahrung.length == 0) {
      validationErrors.push('Bitte wählen Sie eine Berufserfahrung');
    }
    if (arbeitnehmersucheChange.auswahlAusbildungsstand.length == 0) {
      validationErrors.push('Bitte wählen Sie einen Ausbildungsstand');
    }
    if (!arbeitnehmersucheChange.auswahlBeruf) {
      validationErrors.push('Bitte geben Sie einen Beruf ein');
    }
    if (!arbeitnehmersucheChange.gehalt) {
      validationErrors.push('Bitte geben Sie ein Gehalt ein');
    }
    if (!arbeitnehmersucheChange.standort) {
      validationErrors.push('Bitte geben Sie einen Arbeitsort ein');
    }
    if (arbeitnehmersucheChange.auswahlSprache.length == 0) {
      validationErrors.push('Bitte wählen Sie eine Sprache');
    }
    if (arbeitnehmersucheChange.auswahlArbeitsverhaeltnis.length == 0) {
      validationErrors.push('Bitte wählen Sie ein Arbeitsverhältnis');
    }
    if (!arbeitnehmersucheChange.auswahlMontage) {
      validationErrors.push('Bitte wählen Sie eine Montage');
    }
    if (!arbeitnehmersucheChange.zeitpunkt) {
      validationErrors.push('Bitte wählen Sie einen Zeitpunkt');
    }
    return validationErrors;
  }

  function handleForm() {
    let validationErrors: string[] = getValidationErrors();

    if (validationErrors.length > 0) {
      window.scroll(0, 0);
      validationErrors.forEach(error => toast.error(error));
    } else {
      arbeitnehmersucheChange.userID = account.id;

      if (arbeitnehmersucheChange.id) {
        dispatch(updateEntityLocal(arbeitnehmersucheChange))
          .then(() => navigate('/suche/arbeitgeber/profiles'))
          .catch(error => {
            console.error('Error creating entities:', error);
            toast.error('Error creating entities', error);
          });
      } else {
        dispatch(createEntity(arbeitnehmersucheChange))
          .then(() => navigate('/suche/arbeitgeber/profiles'))
          .catch(error => {
            console.error('Error creating entities:', error);
            toast.error('Error creating entities', error);
          });
      }
    }
  }

  /*SEARCH*/
  /*--------------------------------------------*/
  const [colEntries, setColEntries] = useState<any>([]);
  const [allBerufe, setAllBerufe] = useState<any[]>();

  const useBerufe = (): string[] | null => {
    const [berufeList, setBerufeList] = useState<string[] | null>(() => {
      const cachedBerufe = localStorage.getItem('berufeList');
      return cachedBerufe ? JSON.parse(cachedBerufe) : null;
    });

    useEffect(() => {
      if (!berufeList) {
        fetchBerufe().then(data => {
          setBerufeList(data);
          localStorage.setItem('berufeList', JSON.stringify(data));
        });
      }
    }, [berufeList]);

    return berufeList;
  };

  const fetchedBerufe = useBerufe();
  useEffect(() => {
    if (fetchedBerufe) {
      setAllBerufe(fetchedBerufe);
    }
  }, [fetchedBerufe]);

  function handleTyping(value: string) {
    value = value.toUpperCase();

    updateArbeitnehmerSuche('auswahlBeruf', value);

    if (value.length === 0) {
      setColEntries(null);
    } else {
      const filteredBerufe = Object.entries(allBerufe)
        .filter(([, val]) => val.name.toLowerCase().includes(value.toLowerCase()))
        .map(([, val]) => val.name)
        .slice(0, 5);
      setColEntries(filteredBerufe);
    }
  }

  function updateArbeitnehmerSuche(field: string, value: any) {
    setArbeitnehmersucheChange({ ...arbeitnehmersucheChange, [field]: value });
  }

  /*------------------------------------*/

  function setArbeitsort(arbeitsort: string) {
    const arbeitsortVal = {
      arbeitsort: arbeitsort,
      flexibel: false,
      umkreis: '0km',
    };

    updateArbeitnehmerSuche('standort', arbeitsortVal);
  }

  return (
    <div>
      <div className="padding-30"></div>
      <section>
        <div className="container-std w-container">
          <div id="w-node-_0ef209d8-ec40-fad7-0e3f-9b9dac516fa0-488e02aa" className="card-edit-dark-grey-560px">
            <h1 className="heading-3">Suchprofil erstellen</h1>

            <div className="form-block-3 w-form">
              <div className="form">
                <p className="paragraph-workflow">
                  <strong>Danach suchen Sie</strong>
                </p>

                {/*Beruf */}
                <FlendlyValidateField
                  placeholder="Bitte geben Sie Ihren Beruf ein"
                  label="Beruf"
                  inputClass="text-field-45px w-input"
                  type="text"
                  name="berufe"
                  id="beruf-auswahl"
                  onChange={event => handleTyping(event.target.value)}
                  value={arbeitnehmersucheChange.auswahlBeruf}
                  wrapperClass="paragraph-workflow paragraph-display-start"
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div className="paragraph-workflow">
                    {colEntries &&
                      colEntries.map((value: string, index: number) => (
                        <div key={index}>
                          <div
                            key={value}
                            className={
                              arbeitnehmersucheChange.auswahlBeruf === value ? 'click-button-medium highlighted' : 'click-button-medium'
                            }
                            onClick={() => {
                              updateArbeitnehmerSuche('auswahlBeruf', value);
                              setColEntries(null);
                            }}
                          >
                            {value}
                          </div>
                          <div className="padding-3"></div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="divider" />

                <GenericAuswahl
                  onAuswahlChange={value => updateArbeitnehmerSuche('auswahlAusbildungsstand', value)}
                  maxSelect={6}
                  minSelect={1}
                  initialValue={arbeitnehmersucheChange.auswahlAusbildungsstand}
                  col={Ausbildungsstand}
                  title={'Auswahl Ausbildungsstand'}
                  label={'Ausbildunggstand'}
                />

                <div className="divider" />

                <GenericAuswahl
                  onAuswahlChange={value => updateArbeitnehmerSuche('auswahlBerufserfahrung', value)}
                  maxSelect={1}
                  initialValue={arbeitnehmersucheChange.auswahlBerufserfahrung}
                  col={Berufserfahrung}
                  title={'Auswahl Berufserfahrung'}
                  label={'Berufserfahrung'}
                />

                <div className="divider" />

                <div className="div-block-workflow">
                  {/* Kontaktdaten des unternehmens */}
                  <FlendlyValidateField
                    wrapperClass="wrapper-100"
                    placeholder="z.b 2400€"
                    label="Max. Monatsgehalt (Brutto)"
                    id="arbeitgeber-gehalt"
                    name="gehalt"
                    value={arbeitnehmersucheChange.gehalt}
                    onChange={value => updateArbeitnehmerSuche('gehalt', value.target.value)}
                    headline={'Welches Gehalt möchten Sie maximal zahlen?'}
                  />
                </div>

                <div className="divider" />

                <p className="paragraph-workflow">
                  <strong>Wo ist der Einsatzort?</strong>
                </p>

                <FlendlyValidateField
                  placeholder="Arbeitsort"
                  label="Arbeitsort"
                  inputClass="text-field-45px w-input"
                  type="text"
                  name="arbeitsort"
                  id="arbeitsort-auswahl"
                  onChange={event => setArbeitsort(event.target.value)}
                  value={arbeitnehmersucheChange.standort ? arbeitnehmersucheChange.standort.arbeitsort : ''}
                  wrapperClass="paragraph-workflow paragraph-display-start"
                />

                <div className="divider" />

                {/*Sprachen */}
                <SprachenAuswahl
                  title="Welche Sprache(n) wünschen Sie? (Mehrfachauswahl möglich)"
                  onAuswahlChange={value => updateArbeitnehmerSuche('auswahlSprache', value)}
                  initialValue={arbeitnehmersucheChange.auswahlSprache}
                />

                <div className="divider" />

                {/*Beschäftigungsform */}
                <GenericAuswahl
                  onAuswahlChange={value => updateArbeitnehmerSuche('auswahlArbeitsverhaeltnis', value)}
                  initialValue={arbeitnehmersucheChange.auswahlArbeitsverhaeltnis}
                  col={Arbeitsverhaeltnis}
                  title={'Welche Arbeitsverhältnisse suchen Sie?'}
                  label={'Arbeitsverhältnis'}
                />

                <div className="divider"></div>

                {/*Führerschein */}
                <div>
                  <FuehrerscheinAuswahl
                    exclude={[License.AKTUELL_KEINEN, License.KEINEN]}
                    title={'Welche Führerscheine wünschen Sie ? (Optional)'}
                    onAuswahlChange={(value: string) => updateArbeitnehmerSuche('auswahlLicense', value)}
                    initialValue={arbeitnehmersucheChange.auswahlLicense}
                  />
                </div>

                <div className="divider"></div>

                {/*Montageeinsätze */}
                <MontageAuswahl
                  title={'Sind Montageeinsätze nötig?'}
                  montageAuswahl={Object.values(MontageArbeitgeber)}
                  onAuswahlChange={(value: string) => updateArbeitnehmerSuche('auswahlMontage', value)}
                  initialValue={arbeitnehmersucheChange.auswahlMontage}
                />
                <div className="divider"></div>

                {/*Zeitpunkt */}
                <ZeitpunktAuswahl
                  title={'Wann möchten Sie die Stelle besetzen?'}
                  onAuswahlChange={(value: string) => updateArbeitnehmerSuche('zeitpunkt', value)}
                  initialValue={arbeitnehmersucheChange.zeitpunkt}
                />

                <div className="divider"></div>
                <div className="div-block-workflow">
                  <div className={'flendly-button-bar'}>
                    <Link className="button-workflow-forward w-button" to="/suche/arbeitgeber/profiles">
                      Zurück
                    </Link>

                    <div className="call-to-action-max w-button" onClick={() => handleForm()}>
                      Speichern
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="padding-20"></div>
        </div>
      </section>
    </div>
  );
};

export default SucheArbeitgeberProfileCreate;
