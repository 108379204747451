import './home.scss';

import React, { useEffect } from 'react';

import { getRegisterUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import Testimonial from 'app/shared/slider/testimonial';

export const Arbeitnehmer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <body className="body-white">
      <div
        data-w-id="c8d14f16-9cae-1193-1e96-41afa0a5ca8a"
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-standard w-nav"
      ></div>
      <div className="section-3">
        <div className="padding-section-30"></div>
        <div className="container-hero-std centered w-container">
          <div className="div-block-33">
            <h1 className="heading-12">
              <strong className="bold-text-8">Bewerben waren gestern!</strong>
            </h1>
            <div className="padding-15"></div>
            <p className="paragraph-15">
              Erstelle in nur 2 Min. kostenlos und unverbindlich Dein Profil und Unternehmen schicken Dir direkt{' '}
              <strong>Stellenangebote </strong>inkl. <strong>Gehaltsangabe</strong>!
            </p>
            <div className="padding-15"></div>
            <div className="div-block-34">
              <a href={getRegisterUrl()} className="call-to-action-max w-button">
                JETZT kostenlos Angebote erhalten
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <h1 className="heading-1">
          <strong>Deine Vorteile bei Flendy</strong>
          <br />
        </h1>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Keine Bewerbungen &amp; kein Anschreiben</p>
                </h1>
                <p className="margin-bottom-24px">Unternehmen finden Dich und machen Dir Angebote inkl. Gehaltsangabe.</p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Hier KOSTENLOS LOSLEGEN
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Angebot_an_Arbeitnehmer.png.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-right">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Arbeitgeber nach Deinen Bedürfnissen</p>
                </h1>
                <p className="margin-bottom-24px">Sag uns was Dir wichtig ist und wir finden genau das richtige Unternehmen für Dich.</p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Wünsche angeben
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Auswahl_Arbeitgeber_Eigenschaften.png.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Stellenangebote mit einem Klick annehmen</p>
                </h1>
                <p className="margin-bottom-24px">Super Angebot erhalten? Mit nur einem Klick hast Du ein Bewerbungsgespräch.</p>
                <a href={getRegisterUrl()} className="call-to-action-max w-button">
                  Profil erstellen
                </a>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/Angenommes_Angebot.svg" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="padding-section-30"></div>
      <section className="section-image6-green">
        <div className="padding-section-60"></div>
        <div className="container-std">
          <h2 className="heading-2 white">Was unsere Kunden über uns sagen</h2>
          <Testimonial />
        </div>
        <div className="padding-section-60"></div>
      </section>
      <div className="section-plain-white-copy">
        <div className="padding-section-60"></div>
        <h1 className="heading-1 white">
          <strong className="bold-text-9">Melde Dich jetzt unverbindlich &amp; kostenlos an</strong>
          <br />
        </h1>
        <div className="padding-30"></div>
        <a href={getRegisterUrl()} className="call-to-action-max w-button">
          In nur 2 Minuten registrieren
        </a>
        <div className="padding-section-60"></div>
      </div>
      <div className="section-ci-gradient">
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1 white">Bewerben war gestern</h1>
          <h2 className="heading-float-2-9">
            Mit Flendly erhältst Du künftig Angebote von Unternehmen, ohne etwas dafür zu tun. Und das völlig{' '}
            <span className="text-span-4">kostenlos</span>.
          </h2>
          <div className="w-layout-grid grid-std">
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647223-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">1</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647226-2141cc0b" className="heading-4 white">
                Kostenlos
                <br />
                registrieren
              </h2>
              <p className="paragraph-std white">
                In wenigen Minuten Dein persönliches Profil anlegen. Völlig kostenlos und unverbindlich.
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164722c-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">2</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-63060164722f-2141cc0b" className="heading-4 white">
                Unternehmen
                <br />
                Ausschließen
              </h2>
              <p className="paragraph-std white">
                Alle Unternehmen ausschließen, von denen Du nicht gefunden werden willst, wie z.B. Dein aktueller Arbeitgeber.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647235-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">3</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647238-2141cc0b" className="heading-4 white">
                Angebot <br />
                erhalten
              </h2>
              <p className="paragraph-std white">
                Du erhältst Angebote von Unternehmen die Deinen Anforderungen (Gehalt, Ort…) entsprechen.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164723e-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">4</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647241-2141cc0b" className="heading-4 white">
                Angebote
                <br />
                Anfragen<strong> </strong>
              </h2>
              <p className="paragraph-std white">
                Falls Du ein Unternehmen findest, das Dich besonders interessiert, frag mit einem einzigen Klick ein Angebot an.
              </p>
            </div>
          </div>
          <div className="padding-30"></div>
        </div>
      </div>
    </body>
  );
};

export default Arbeitnehmer;
