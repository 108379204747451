import React, { useEffect, useRef, useState } from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useNavigate } from 'react-router-dom';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { getRecommendationForArbeitgeberAndArbeitnehmer } from 'app/modules/suche/recommendation/recommendation.reducer';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { getArbeitnehmerSkillsByKey, getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { License } from 'app/shared/model/enumerations/license.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { MontageAuswahl } from 'app/shared/components/montage-auswahl';
import { Arbeitnehmerskills } from 'app/shared/model/enumerations/arbeitnehmerskills.model';

interface NotificationProps {
  notification: INotification;
  closeModal: () => void;
}

export const Anfrage: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const account = useAppSelector(state => state.authentication.account);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const recommendation: IRecommendation = useAppSelector(state => state.recommendation.entity);

  useEffect(() => {
    if (notification) {
      let arbeitnehmerId = notification.keyValueMap['arbeitnehmerId'];

      dispatch(getArbeitnehmer(arbeitnehmerId));
      dispatch(getSuchkriterienByUserId(arbeitnehmerId));
    }
  }, [notification]);

  useEffect(() => {
    if (arbeitnehmer.userID) {
      dispatch(
        getRecommendationForArbeitgeberAndArbeitnehmer({
          arbeitnehmerId: arbeitnehmer.userID,
          arbeitgeberId: account.id,
        })
      );
    }
  }, [arbeitnehmer, suchkriterien]);

  function loaded() {
    return arbeitnehmer.userID && suchkriterien.userID && recommendation.id;
  }

  function handleNotInterested() {
    closeModal();
    navigate('/suche/arbeitnehmer/anfrage/reject/' + recommendation.id);
  }

  function isFlexibel() {
    return suchkriterien.arbeitsort['flexibel'] === 'flexibel';
  }

  function handleSendOffer() {
    closeModal();
    navigate('/suche/arbeitgeber/angebot/' + recommendation.id);
  }

  return (
    <div>
      {loaded() && (
        <div>
          <section>
            <div className="container-std w-container">
              <div className="padding-30-speziale"></div>
              <div className="wrapper-95">
                <div className="card-read-dark-grey">
                  <div className="padding-5"></div>
                  <div className="card-heading">
                    <strong>Anfrage Für eine Anstellung</strong>
                  </div>
                  <div className="padding-10"></div>
                  <p className="paragraph-10">
                    {arbeitnehmer.auswahlAnrede} {arbeitnehmer.name} hat<strong> </strong>Ihnen eine Anfrage zur Anstellung gesendet.
                  </p>
                  <div id="8-single-select" className="w-layout-grid grid-cancel-send-215px">
                    <a
                      id="w-node-f72269b5-ca57-581b-5148-57a9b5da1fe5-1e498197"
                      onClick={handleNotInterested}
                      className="call-to-action-red-max-100 w-button"
                    >
                      Anfrage ablehnen
                    </a>
                    <div
                      id="w-node-fbe6c914-187f-9ae5-f3d7-3704b6ba20bc-1e498197"
                      onClick={() => handleSendOffer()}
                      className="call-to-action-max-100 w-button"
                    >
                      Angebot senden
                    </div>
                  </div>
                </div>
                <div className="card-read-dark-grey">
                  <div className="accordion-toggle w-dropdown-toggle">
                    <div>
                      <strong className="card-heading">
                        Profil {arbeitnehmer.auswahlAnrede} {arbeitnehmer.vorname} {arbeitnehmer.name},{' '}
                        {getAgeByDateOfBirth(arbeitnehmer.geburtsdatum)}
                      </strong>
                    </div>
                  </div>
                  <div className="dropdown-accordion w-dropdown-list">
                    <div className="card-transparent">
                      <div className="w-layout-grid grid-1x4-170px-1-1">
                        <div>
                          <div className="card-text-blue-first">Ich suche</div>
                          <div className="card-field-min-215px">
                            {suchkriterien.auswahlArbeitsverhaeltnis.map(verhaeltnis => Arbeitsverhaeltnis[verhaeltnis])}
                          </div>
                        </div>
                      </div>
                      <div className="card-text-blue">Gewünschtes mindestgehalt (netto)</div>
                      <div className="card-field-min-215px">
                        {suchkriterien.auswahlGehalt} € pro Monat
                        <br />
                      </div>

                      <div className="card-text-blue">Meine Qualifikationen</div>
                      <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ce4-1e498197" className="div-block-19-copy">
                        <div className="div-block-18-copy">
                          {suchkriterien.auswahlBeruf.map((beruf, index) => (
                            <div className="div-block-18-copy">
                              <div className="w-layout-grid _1x1-copy">
                                <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ce7-1e498197" className="text-grid-white-backr-accent">
                                  {beruf.auswahlBeruf}
                                </div>
                              </div>
                              <div className="horizontal-padding-3"></div>
                              <div className="w-layout-grid grid-job-details-copy">
                                <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ceb-1e498197" className="text-grid-white-backr">
                                  {beruf.auswahlAusbildungsstand.map(ausbildungsstand => Ausbildungsstand[ausbildungsstand])}
                                </div>
                                <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9ced-1e498197" className="text-grid-white-backr">
                                  {beruf.auswahlBerufserfahrung.map(berufserfahrung => Berufserfahrung[berufserfahrung])}
                                </div>
                                <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9cef-1e498197" className="text-grid-white-backr">
                                  {beruf.zusatzqualifikationen}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="card-text-blue">Meine Eigenschaften</div>
                      <div className="w-layout-grid grid-1x4-170px">
                        {arbeitnehmer.auswahlArbeitnehmerEigenschaften.map((eigenschaft, index) => (
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d07-1e498197" className="text-grid-white-backr">
                            {getArbeitnehmerSkillsByKey(eigenschaft)}
                          </div>
                        ))}
                      </div>

                      {/*unternehmen benefits*/}
                      <div className="card-text-blue">Das ist mir in einem Unternehmen wichtig</div>
                      <div className="w-layout-grid grid-1x4-170px">
                        {suchkriterien.auswahlEigenschaften.map((eigenschaft, index) => (
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d1a-1e498197" className="text-grid-white-backr">
                            {getEigenschaftByKey(eigenschaft)}
                          </div>
                        ))}
                      </div>
                      <div className="card-text-blue">Führerscheine</div>
                      <div className="w-layout-grid grid-1x4-170px">
                        {suchkriterien.auswahlLicense.map((license, index) => (
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d2d-1e498197" className="text-grid-white-backr">
                            {License[license]}
                          </div>
                        ))}
                      </div>

                      <div className="card-text-blue">Sprachen</div>
                      <div className="w-layout-grid">
                        {suchkriterien.auswahlSprache.map((sprache, index) => (
                          <div className="flex-content-cust w-layout-grid grid-77">
                            <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d36-1e498197" className="text-block-left-copy">
                              {sprache.sprache}
                            </div>
                            <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d38-1e498197" className="text-block-left-copy">
                              {sprache.niveau}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="card-text-blue">Gewünschtes Beschäftigungsverhältnis</div>
                      <div className="w-layout-grid grid-1x4-170px">
                        {suchkriterien.auswahlArbeitsverhaeltnis.map((arbeitsverhaeltnis, index) => (
                          <div id="w-node-_3f93d8b6-31bc-4836-ee2c-4c038bdb9d47-1e498197" className="text-grid-white-backr">
                            {Arbeitsverhaeltnis[arbeitsverhaeltnis]}
                          </div>
                        ))}
                      </div>

                      <div className="card-text-blue">Wo ich arbeiten möchte</div>
                      {isFlexibel() ? (
                        <div className="card-field-min-215px">
                          Ich bin komplett flexibel
                          <br />
                        </div>
                      ) : (
                        <div className="w-layout-grid grid-77">
                          <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f1-1e498191" className="text-block-left-copy">
                            {suchkriterien.arbeitsort['arbeitsort']}
                          </div>
                          <div id="w-node-_0566ba43-cfe6-6015-5e9c-3584eb4ea8f3-1e498191" className="text-block-5">
                            +{suchkriterien.arbeitsort['umkreis']}
                          </div>
                        </div>
                      )}

                      <div className="card-text-blue">Bereitschaft für Montageeinsätze</div>
                      <div className="card-field-min-215px">{suchkriterien.auswahlMontage} </div>

                      <div className="card-text-blue">Verfügbarkeit</div>
                      <div className="card-field-min-215px">{suchkriterien.auswahlZeitpunkt.zeitpunkt}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="padding-20"></div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};
