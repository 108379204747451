export const getLoginUrl = () => {
  const port = location.port ? `:${location.port}` : '';

  // If you have configured multiple OIDC providers, then, you can update this URL to /login.
  // It will show a Spring Security generated login page with links to configured OIDC providers.
  return `//${location.hostname}${port}/oauth2/authorization/oidc`;
};

export const getRegisterUrl = () => {
  const url = window.location.href;
  if (url.includes('localhost')) {
    return (
      'http://localhost:9080/realms/flendly/protocol/openid-connect/registrations?client_id=web_app&response_type=code&scope=openid&redirect_uri=http:' +
      getLoginUrl()
    );
  } else {
    return (
      'https://auth.flendly.de/realms/flendly/protocol/openid-connect/registrations?client_id=web_app&response_type=code&scope=openid&redirect_uri=https:' +
      getLoginUrl()
    );
  }
};

export const REDIRECT_URL = 'redirectURL';
