import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getRecommendationsForArbeitgeber, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { getArbeitnehmer as getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { getAgeByDateOfBirth } from 'app/shared/util/date-utils';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { toast } from 'react-toastify';
import { getArbeitnehmerSkillsByKey, getEigenschaftByKey } from 'app/shared/util/entity-utils';

export const SucheArbeitgeber = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAppSelector(state => state.authentication.account);

  const { id } = useParams<'id'>();

  const recommendations: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const [recommendationsList, setRecommendationsList] = React.useState<IRecommendation[]>([]);
  const [recommendation, setRecommendation] = React.useState<IRecommendation>(null);

  const [flyOut, setFlyOut] = useState(false);

  useEffect(() => {
    dispatch(getArbeitnehmerSuche(id));
    dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
  }, []);

  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      setRecommendationsList(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    if (recommendationsList && recommendationsList.length > 0) {
      let iRecommendation = recommendationsList[0];
      setRecommendation(iRecommendation);
      dispatch(getArbeitnehmer(iRecommendation.arbeitnehmerId));
      dispatch(getSuchkriterienByUserId(iRecommendation.arbeitnehmerId));
    } else {
      dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
    }
  }, [recommendationsList]);

  function handleRecommendationDeclined(recommendation: IRecommendation) {
    // Starte die Animation, indem du `flyOut` auf true setzt
    setFlyOut(true);

    // Zeitverzögerung für den Zustand nach der Animation (bevor der Zustand aktualisiert wird)
    setTimeout(() => {
      const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };
      dispatch(updateRecommendation(updatedRecommendation))
        .then(() => {
          toast.info('Arbeitnehmer wurde abgelehnt');
        })
        .then(() => {
          let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
          if (iRecommendations.length > 0) {
            setRecommendationsList(iRecommendations);
            setFlyOut(false); // Zurücksetzen des Zustands für die nächste Karte
          } else {
            dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false }));
            setRecommendation(null);
            setFlyOut(false); // Zurücksetzen des Zustands für die nächste Karte
          }
        });
    }, 500); // Die Dauer der Animation sollte mit der CSS-Animation (0.5s) übereinstimmen
  }

  function handleShowDeclinedRecommendations() {
    dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: true }));
  }

  function loaded() {
    return recommendation && arbeitnehmer && suchkriterien && arbeitnehmersuche;
  }

  return (
    <div>
      {loaded() ? (
        account.userSettings.searchDeactivated ? (
          <section className="container-std w-container">
            <div className="flex-content-cust card-edit-dark-grey-735px-1">
              <div>
                <h1 className="heading-3-copy-copy">Ihre Suche ist zurzeit deaktiviert</h1>
                <p className="paragraph-std">
                  Sie haben die Suche deaktiviert. Um die Suche nutzen zu können, aktivieren Sie diese in Ihrem Profil.
                  <br />
                </p>
              </div>
              <div id="8-single-select">
                <Link to={`/arbeitgeber/einstellungen`} className="call-to-action-max w-button">
                  Jetzt Suche aktivieren
                </Link>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <div className="container-std w-container">
              <div className="padding-30"></div>
              <div className="wrapper-930px">
                <div className="div-block-filter">
                  <div className="flex-content-cust">
                    <Link to={'/suche/arbeitgeber/profiles/update/' + id} className="link-block-13 w-inline-block">
                      <img
                        src="../../../../content/images/edit2.svg"
                        loading="lazy"
                        id="w-node-_62efc283-2f8a-058c-5911-e86e0b813170-8d3b9927"
                        alt="Ein stilisiertes Symbol für das Bearbeiten von Informationen, dargestellt durch ein Icon bestehend aus einem Stift auf Papier."
                        className="image-15"
                      />
                    </Link>
                    <div className="text-block-27">Suchprofil</div>
                  </div>

                  <div className="text-block-filter">{arbeitnehmersuche.auswahlBeruf}</div>
                  <div className="text-block-filter">
                    {arbeitnehmersuche.auswahlAusbildungsstand.map(value => Ausbildungsstand[value] + ' ')}
                  </div>
                  <div className="text-block-filter">Quereinsteiger</div>
                  <div className="text-block-filter">{arbeitnehmersuche.standort.arbeitsort}</div>
                  <div className="text-block-filter">
                    {arbeitnehmersuche.auswahlArbeitsverhaeltnis.map(value => Arbeitsverhaeltnis[value] + ' ')}
                  </div>
                  <div className="text-block-filter">max. {arbeitnehmersuche.gehalt}€/Monat</div>
                </div>

                <div
                  className={`card-ag-suche ${flyOut ? 'fly-out-left' : ''}`} // Animation anwenden, wenn flyOut true ist
                >
                  <div id="w-node-eb838c48-48dd-4e21-8f28-9a96ed7383b3-1e498192" className="card-ag-suche">
                    <Link to={'/suche/arbeitgeber/details/' + recommendation.id} className="link-block-company-cards w-inline-block"></Link>
                    <h1 className="heading-3">
                      <strong className="bold-text-4">
                        {arbeitnehmer.auswahlAnrede} {arbeitnehmer.name}, {getAgeByDateOfBirth(arbeitnehmer.geburtsdatum)}
                      </strong>
                    </h1>
                    <div className="padding-20-speziale"></div>
                    <div className="w-layout-grid grid-top-search-card-1x3">
                      <div className="w-layout-grid grid-search-card-1x2-200px">
                        <img
                          src="../../../../content/images/euro.png"
                          loading="lazy"
                          width="233"
                          id="w-node-f5cddd2e-d489-113a-bc2f-3eb44b9821b6-1e498192"
                          alt="Ein Symbol für das Gehalt, dargestellt durch ein Euro-Symbol. Das Symbol symbolisiert das angebotene bzw. geforderte Gehalts."
                          className="image-company-card-icons"
                        />
                        <div id="w-node-eb838c48-48dd-4e21-8f28-9a96ed7383bd-1e498192" className="text-grid-white-backr">
                          mind. {suchkriterien.auswahlGehalt} € / Monat
                        </div>
                      </div>
                      <div className="w-layout-grid grid-search-card-1x2-200px">
                        <img
                          src="../../../../content/images/calender-2.png"
                          loading="lazy"
                          width="128"
                          id="w-node-_58d8eacf-772a-71a9-f8c7-a9622dbdb3bd-1e498192"
                          alt="Ein Symbol für das mögliche Startdatum im Unternehmen, dargestellt durch einen Kalender. Das Symbol symbolisiert den möglichen Startzeitpunkt im "
                          className="image-company-card-icons"
                        />
                        <div id="w-node-_58d8eacf-772a-71a9-f8c7-a9622dbdb3be-1e498192" className="text-grid-white-backr">
                          {suchkriterien.auswahlZeitpunkt.zeitpunkt}
                        </div>
                      </div>
                      <div className="w-layout-grid grid-search-card-1x2-200px">
                        <img
                          src="../../../../content/images/luggage1.png"
                          loading="lazy"
                          width="128"
                          id="w-node-_58d8eacf-772a-71a9-f8c7-a9622dbdb3c1-1e498192"
                          alt="Ein Symbol für Reisetätigkeiten, dargestellt durch einen Koffer. Das Symbol symbolisiert Geschäftsreisen."
                          className="image-company-card-icons"
                        />
                        <div id="w-node-_58d8eacf-772a-71a9-f8c7-a9622dbdb3c2-1e498192" className="text-grid-white-backr">
                          {suchkriterien.auswahlMontage}
                        </div>
                      </div>
                    </div>

                    <div className="w-layout-grid _1x2-jobs">
                      <img
                        src="../../../../content/images/schraubenschlüssel_1schraubenschlüssel.webp"
                        loading="lazy"
                        width="512"
                        id="w-node-d5304346-c4cc-d746-b0b9-a5a18d21ff4a-1e498192"
                        alt="Ein Symbol für Berufe, dargestellt durch ein Icon bestehend aus einem gekreuzten Schraubenschlüssel und Schraubendreher."
                        className="image-company-card-icons"
                      />

                      {suchkriterien.auswahlBeruf.map((beruf, index) => (
                        <div key={index}>
                          <div className="w-layout-grid">
                            <div id="w-node-_79361e00-97c8-7855-61e7-501debb2124a-1e498192" className="text-grid-white-backr-accent">
                              {beruf.auswahlBeruf}
                            </div>
                          </div>
                          <div className="w-layout-grid grid-job-details">
                            <div id="w-node-_79361e00-97c8-7855-61e7-501debb2124e-1e498192" className="text-grid-white-backr">
                              {beruf.auswahlAusbildungsstand.map(value => Ausbildungsstand[value] + ' ')}
                            </div>
                            <div id="w-node-_79361e00-97c8-7855-61e7-501debb21250-1e498192" className="text-grid-white-backr">
                              {beruf.auswahlBerufserfahrung.map(value => Berufserfahrung[value] + ' ')}
                            </div>
                            <div id="w-node-_79361e00-97c8-7855-61e7-501debb21252-1e498192" className="text-grid-white-backr">
                              {beruf.zusatzqualifikationen}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="w-layout-grid _1x2-jobs">
                      <div id="w-node-_71bafdbe-7875-b794-e0ab-de91ee369b96-1e498192" className="div-block-20">
                        <img
                          src="../../../../content/images/checklist_1checklist.webp"
                          loading="lazy"
                          id="w-node-_833deaab-312d-0835-5c84-2bac65a06e9e-1e498192"
                          alt="Ein Symbol für Eigenschaften, dargestellt durch ein Klemmbrett mit einer angedeuteten Checkliste. Das Symbol repräsentiert die  Merkmalen oder Eigenschaften des Mitarbeiters oder des Unternehmens."
                          className="image-company-card-icons-2"
                        />
                      </div>
                      <div className="w-layout-grid _1x5-search-attributes">
                        {suchkriterien.auswahlEigenschaften.map((eigenschaft, index) => (
                          <div key={index} id="w-node-_4b6f295c-c931-e9a6-a34d-8319ba13b9b5-1e498192" className="text-grid-white-backr">
                            {getEigenschaftByKey(eigenschaft)}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div id="104-single-select45" className="w-layout-grid company-card-buttons">
                      <div
                        id="w-node-efe63d2a-24fe-deaf-e5d9-dbdbc9449e82-1e498192"
                        className="call-to-action-red-max-100"
                        onClick={() => handleRecommendationDeclined(recommendation)}
                      >
                        Nicht interessant
                      </div>
                      <div
                        onClick={() => navigate('/suche/arbeitgeber/angebot/' + recommendation.id)}
                        id="w-node-efe63d2a-24fe-deaf-e5d9-dbdbc9449e84-1e498192"
                        className="call-to-action-max-100"
                      >
                        Einladung senden
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="padding-20"></div>
            </div>
          </section>
        )
      ) : (
        <section className="container-std w-container">
          <div className="flex-content-cust card-edit-dark-grey-735px-1">
            <div>
              <h1 className="heading-3">Keine weiteren Treffer für Ihre Suche</h1>
              <p className="paragraph-std">
                Im Moment gibt es keine weiteren passenden Kandidaten für dieses Suchprofil. Falls Sie nicht warten wollen bis weitere
                Kandidaten verfügbar sind, können Sie folgendes tun:
                <br />
              </p>
            </div>
            <div className="padding-5"></div>
            <div>
              <p className="paragraph-std">
                Passen Sie Ihr Suchprofil an, um mehr Kandidaten zu erreichen (z.B. Gehalt erhöhen, Qualifikation oder Berufserfahrung
                reduzieren)
              </p>
            </div>

            <div id="8-single-select">
              <Link to={`/suche/arbeitgeber/profiles/update/${id}`} className="call-to-action-max w-button">
                jetzt profil bearbeiten
              </Link>
            </div>
            <div className={'wrapper-100'}>
              <p className="paragraph-std">
                ODER
                <br />‍<br />
                Schauen Sie die Liste der abgelehnten Kandidaten nochmals an. In der schnellen Durchsicht drückt man vielleicht einmal zu
                schnell auf den &quot;nicht interessant&quot; Button.
              </p>
            </div>
            <div id="8-single-select">
              {/*TODO abgelehnte Unternehmen nochmal einblenden*/}
              <a className="call-to-action-max w-button" onClick={() => handleShowDeclinedRecommendations()}>
                Abgelehnte Kanditaten nochmals anschauen
              </a>
            </div>
            <div className="wrapper-100">
              <p className="paragraph-std">
                ODER
                <br />
              </p>
            </div>
            <div id="8-single-select">
              {/*TODO Option für Notification wenn weitere Unternehmen verfügbar sind*/}
              <a href="#" className="call-to-action-max w-button">
                Warten bis weitere Kanditaten verfügbar sind
              </a>
            </div>
            <div className="padding-10"></div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SucheArbeitgeber;
